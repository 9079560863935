export class HttpHeaderNames
{
    // NOTE: Un-comment these properties as needed.  The un-referenced properties
    //       were left commented to reduce the size of the minified class.
    //       You may also need to update the CORS policy on the server to make
    //       these visible to this application. (CorsPolicyBuilder.WithExposedHeaders(...))

    public static  Accept: string = 'Accept';
    // public static IfMatch: string = 'If-Match';
    // public static IfModifiedSince: string = 'If-Modified-Since';
    // public static IfNoneMatch: string = 'If-None-Match';
    // public static IfRange: string = 'If-Range';
    // public static IfUnmodifiedSince: string = 'If-Unmodified-Since';
    // public static LastModified: string = 'Last-Modified';
    public static Location: string = 'Location';
    // public static MaxForwards: string = 'Max-Forwards';
    // public static Method: string = ':method';
    // public static Origin: string = 'Origin';
    // public static Path: string = ':path';
    // public static Pragma: string = 'Pragma';
    // public static ProxyAuthenticate: string = 'Proxy-Authenticate';
    // public static ProxyAuthorization: string = 'Proxy-Authorization';
    // public static Host: string = 'Host';
    // public static Range: string = 'Range';
    public static RetryAfter: string = 'Retry-After';
    // public static Scheme: string = ':scheme';
    // public static Server: string = 'Server';
    // public static SetCookie: string = 'Set-Cookie';
    // public static Status: string = ':status';
    // public static StrictTransportSecurity: string = 'Strict-Transport-Security';
    // public static TE: string = 'TE';
    // public static Trailer: string = 'Trailer';
    // public static TransferEncoding: string = 'Transfer-Encoding';
    // public static Upgrade: string = 'Upgrade';
    // public static UserAgent: string = 'User-Agent';
    // public static Vary: string = 'Vary';
    // public static Via: string = 'Via';
    // public static Warning: string = 'Warning';
    // public static Referer: string = 'Referer';
    // public static From: string = 'From';
    // public static Expect: string = 'Expect';
    // public static Expires: string = 'Expires';
    // public static AcceptCharset: string = 'Accept-Charset';
    // public static AcceptEncoding: string = 'Accept-Encoding';
    // public static AcceptLanguage: string = 'Accept-Language';
    // public static AcceptRanges: string = 'Accept-Ranges';
    // public static AccessControlAllowCredentials: string = 'Access-Control-Allow-Credentials';
    // public static AccessControlAllowHeaders: string = 'Access-Control-Allow-Headers';
    // public static AccessControlAllowMethods: string = 'Access-Control-Allow-Methods';
    // public static AccessControlAllowOrigin: string = 'Access-Control-Allow-Origin';
    // public static AccessControlExposeHeaders: string = 'Access-Control-Expose-Headers';
    // public static AccessControlMaxAge: string = 'Access-Control-Max-Age';
    // public static AccessControlRequestHeaders: string = 'Access-Control-Request-Headers';
    // public static AccessControlRequestMethod: string = 'Access-Control-Request-Method';
    // public static Age: string = 'Age';
    // public static Allow: string = 'Allow';
    // public static Authority: string = ':authority';
    public static Authorization: string = 'Authorization';
    // public static CacheControl: string = 'Cache-Control';
    // public static ETag: string = 'ETag';
    // public static Date: string = 'Date';
    // public static Cookie: string = 'Cookie';
    public static ContentType: string = 'Content-Type';
    // public static ContentSecurityPolicyReportOnly: string = 'Content-Security-Policy-Report-Only';
    // public static ContentSecurityPolicy: string = 'Content-Security-Policy';
    // public static WebSocketSubProtocols: string = 'Sec-WebSocket-Protocol';
    // public static ContentRange: string = 'Content-Range';
    // public static ContentLocation: string = 'Content-Location';
    // public static ContentLength: string = 'Content-Length';
    // public static ContentLanguage: string = 'Content-Language';
    // public static ContentEncoding: string = 'Content-Encoding';
    public static ContentDisposition: string = 'Content-Disposition';
    // public static Connection: string = 'Connection';
    // public static ContentMD5: string = 'Content-MD5';
    // public static WWWAuthenticate: string = 'WWW-Authenticate';
    public static ApimSubscriptionKey: string = 'Ocp-Apim-Subscription-Key';
    public static EventInteractionType: string = 'X-UW-Event-Interaction-Type';
}